<template>
  <v-app>
    <v-app-bar app dense flat
               :dark="!isDark"
               :color="isDark ? '#282C31':'transparent'"
               style="z-index: 12">
      <div class="h5-app-bar">
        <v-img
            @click="$router.push('/h5/Home')"
            max-width="138"
            height="100%"
            alt="logo"
            contain
            :src="logoBai"
        />
        <!-- <v-img @click.stop="drawer = !drawer" max-width="16" contain :class="drawer?'menuimg':''"
               :src="menuIconBai"></v-img> -->
         <v-img @click.stop="drawer = !drawer" max-width="16" contain
               :src="drawer ? menuIconClose : (isDark ? menuIconBai : menuIconBai)"  />
      </div>
      <div v-show="drawer" @click.stop
          class="app-bar-menu">
        <v-list light>
          <v-list-item to="/h5/develop/Home" @click.stop="drawer = !drawer">
            <v-list-item-title class="menu-title">首页</v-list-item-title>
          </v-list-item>
          <v-list-item to="/h5/develop/Experience" @click.stop="drawer = !drawer"  :class="currentMenu==='/h5/develop/ExperienceDetail'?'v-list-item--active':''">
            <v-list-item-title class="menu-title">教程</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item @click.stop="dialog = true;drawer = !drawer">
            <v-list-item-title class="menu-title">API</v-list-item-title>
          </v-list-item> -->
        </v-list>
      </div>
    </v-app-bar>
    <v-main class="pt-0" style="overflow-y: auto">
      <router-view></router-view>
    </v-main>

    <div class="dialog" v-show="dialog" @click.stop="dialog = false">
      <transition name="fade" mode="out-in" @click.stop>
        <div class="content-tryout" @click.stop>
          <v-img
           @click.stop
            contain
            src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-api.png"
            height="100%"
          >
            <v-card-title class="justify-end pb-0 close-btn">
              <img width="11px" height="12px"  @click.stop="dialog = false" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-clone.png" alt="">
            </v-card-title>
          </v-img>
        </div>
      </transition>
    </div>

  </v-app>
</template>

<script>

import { mapState } from "vuex";

export default {
  name: 'develop',
  data: () => ({
    drawer: false,
    // products: [
    //   {
    //     title: "首页",
    //     router: "/metaverse/h5/develop/Home",
    //     children: []
    //   },
    //   {
    //     title: "教程",
    //     router: "/metaverse/h5/develop/Experience",
    //     children: []
    //   },
    //   {
    //     title: "API",
    //     router: "/metaverse/h5/develop/API",
    //     children: []
    //   },
    // ],
    logoBai: 'https://h5.ophyer.cn/official_website/other/metaverse-logo.png',
    menuIconClose: require("../../../../../public/img/icons/develop/menuIconCloseH5.png"),
    menuIconBai: require("../../../../../public/img/icons/menu/menuBai.png"),
    currentMenu: "/h5/develop/Home",
    dialog: false
  }),
  computed: {
    ...mapState(["officialWebsite"]),
    isDark() {
      if(this.officialWebsite.showReturnTip || this.drawer || this.$route.path === "/h5/develop/ExperienceDetail"){
        return true;
      }
      return false;
    }
  },
  watch: {
    $route(to) { 
      const menuInfo = to.path.split("/");
      if(menuInfo[4] == 'ExperienceDetail'){
        this.currentMenu = '/h5/develop/ExperienceDetail'
        this.drawer=false
      }
      else{
        this.currentMenu = "/h5/" + menuInfo[2]+'/'+ menuInfo[3];
      }
    },
  },
  mounted() {
    let _this = this;
    document.addEventListener("click",()=>{
      _this.currentHover = '';
    })
    const menuInfo = _this.$route.path.split("/");
    this.currentMenu = "/metaverse/" + menuInfo[2]+'/'+ menuInfo[3];
    if(menuInfo[4] === 'ExperienceDetail'){
      _this.currentMenu = '/h5/develop/ExperienceDetail'
    }
  },
  methods: {
    handleMenuClick: function (menu, clickChildren, content){
      
      if(menu.children.length !== 0 && !clickChildren) return;
      if(content && content.router){
        if(content.isNewWindow){
          
          window.open(window.location.protocol + "//" + window.location.host + content.router)
          
        }else {
          this.$router.push(content.router)
        }
      }
      this.showMenuChildren = false;
    }

  }
};
</script>


<style lang="scss" scoped>
::v-deep .white{
  //background: #282C31 !important;
  //box-shadow: 0px 0px 18px 0px rgba(25, 27, 29, 0.5) !important;  
}

.h5-app-bar{
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  .bar-icon::after{
    display: none;
  }
}
.menuimg{
  transform: rotate(90deg);
}
::v-deep .app-bar-menu{
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #4C525D;
  .theme--light.v-list{
    background: #282C31;
    box-shadow: 0px 0px 18px 0px rgba(25, 27, 29, 0.5);
  }
  // .v-list-item--link{
  //   border-bottom: 1px solid #4C525D;
  // }
  // .v-list-item--link:last-child{
  //   border-bottom:unset;
  // }
  .menu-title{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
  }
}

.hover-btn{
  width: 35px;
  height: 35px;
  border-radius: 20px;
  position: fixed;
  bottom: calc(10% - 55px);
  right: 0;
  z-index:5;
  box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
  cursor: pointer;
}

.dialog {
  position: fixed;
  z-index:1001;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.7);
  .content-tryout{
    max-width: 480px;
    max-height: 307px;
    width: 80%;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
    .close-btn {
      cursor: pointer;
    }
  }
}
</style>